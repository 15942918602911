import { api, CommonListParams, Response } from "~/src/lib/api";
import { Address } from "~/src/lib/api/address";
import { Department } from "~/src/lib/api/departments";
import { Follower } from "~/src/lib/api/followers";
import { Id } from "~/src/lib/api/id";
import { JobLevel } from "~/src/lib/api/job-levels";
import { JobStage } from "~/src/lib/api/job-stages";
import { LaborRegime } from "~/src/lib/api/labor-regimes";
import { Page } from "~/src/lib/api/pages";
import { User } from "~/src/lib/api/users";

/**
 * Jobs stats.
 */
export type Stats = {
  total: number;
  published: number;
  pending: number;
  draft: number;
  inactive: number;
};

/**
 * Job status.
 */
export enum Status {
  Draft = "draft",
  Pending = "pending",
  Rejected = "rejected",
  Approved = "approved",
  Published = "published",
  Paused = "paused",
  Closed = "closed",
  Canceled = "canceled",
}

/**
 * Job modal.
 */
export enum Modal {
  Remote = "remote",
  InOffice = "in-office",
  Hybrid = "hybrid",
}

/**
 * Job data.
 */
export type Job = Partial<{
  id: Id;
  createdAt: string;
  updatedAt: string;
  name: string;
  slug: string;
  status: Status;
  numberOfApplicants: number;
  totalSpots: number;
  filledSpots: number;
  availableSpots: number;
  location: Address;
  hiringDeadline: string;
  applicationDeadline: string;
  publishedAt: string;
  role: string;
  wage: number;
  maxWage: number;
  discloseWage: boolean;
  modal: Modal;
  discloseLocation: boolean;
  notes: string;
  bluyIdentifier: string;
  customIdentifier: string;
  availableForPwD: boolean;
  responsibilities: string;
  requirements: string;
  perks: string;
  stages: JobStage[];
  followerIds: Id[];
  followers: Follower[];
  userId: Id;
  user: User;
  companyId: Id;
  laborRegimeId: Id;
  laborRegime: LaborRegime;
  departmentIds: Id[];
  departments: Department[];
  jobLevels: JobLevel[];
  jobLevelIds: Id[];
  internal: boolean;
  applicationCount: number;
}>;

/**
 * Get parameters.
 */
type GetByIdParams = { id: Id };
type GetBySlugParams = { slug: string };

/**
 * List parameters.
 */
export type ListParams = CommonListParams & {
  name?: string;
  company?: Id;
  status?: Status | Status[];
  follower?: Id;
  applicable?: boolean;
  internal?: boolean;
};

/**
 * Get the query key for the resource.
 * @see https://react-query.tanstack.com/guides/query-keys
 * @param params Query parameters.
 * @returns Query key.
 */
export const getQueryKey = (
  params?: GetByIdParams | GetBySlugParams | ListParams | Job,
) => {
  return ["jobs", params].filter(Boolean);
};

/**
 * List jobs.
 * @param opts Query options.
 * @returns A promise of the response.
 */
export const list = async (params: ListParams) => {
  return (await api.get<Response<Job[]>>("/jobs", { params })).data;
};

/**
 * Get a single job by id.
 * @param id Id of the job.
 * @param slug Slug of the job.
 * @returns A promise of the response.
 */
export const get = async (params: GetByIdParams | GetBySlugParams) => {
  const ref = "id" in params ? params.id : params.slug;
  return (await api.get<Response<Job>>(`/jobs/${ref}`)).data;
};

/**
 * Create a new job.
 * @param data New job data.
 * @returns A promise of the response.
 */
export const create = async (data: Job) => {
  return (await api.post<Response<Job>>("/jobs", data)).data;
};

/**
 * Update existing job.
 * @param data Existing job data with id.
 * @returns A promise of the response.
 */
export const update = async ({ id, ...data }: Job & GetByIdParams) => {
  return (await api.patch<Response<Job>>(`/jobs/${id}`, data)).data;
};

/**
 * Remove job.
 * @param id Get parameters.
 */
export const remove = async ({ id }: GetByIdParams) => {
  await api.delete(`/jobs/${id}`);
};

/**
 * Change job status to published.
 * @param params Get parameters.
 */
export const publish = async (job: Job) => {
  await api.patch(`/jobs/${job.id}`, { ...job, status: Status.Published });
};

/**
 * Change job status to closed.
 * @param params Get parameters.
 */
export const close = async (job: Job) => {
  await api.patch(`/jobs/${job.id}`, { ...job, status: Status.Closed });
};

/**
 * Change job status to paused.
 * @param params Get parameters.
 */
export const pause = async (job: Job) => {
  await api.patch(`/jobs/${job.id}`, { ...job, status: Status.Paused });
};

/**
 * Change job status to canceled.
 * @param params Get parameters.
 */
export const cancel = async (job: Job) => {
  await api.patch(`/jobs/${job.id}`, { ...job, status: Status.Canceled });
};

/**
 * Tells if a given job is published.
 * @param job Job data.
 */
export const isPublished = (job?: Job) => {
  return job?.status === Status.Published;
};

/**
 * Tells if a given job is pending .
 * @param job Job data.
 */
export const isPending = (job?: Job) => {
  return job?.status === Status.Pending;
};

/**
 * Tells if a given job is approved.
 * @param job Job data.
 */
export const isApproved = (job?: Job) => {
  return job?.status === Status.Approved;
};

/**
 * Tells if a given job is denied.
 * @param job Job data.
 */
export const isRejected = (job?: Job) => {
  return job?.status === Status.Rejected;
};

/**
 * Tells if a given job is draft.
 * @param job Job data.
 */
export const isDraft = (job?: Job) => {
  return job?.status === Status.Draft;
};

/**
 * Tells if a given job is paused.
 * @param job Job data.
 */
export const isPaused = (job?: Job) => {
  return job?.status === Status.Paused;
};

/**
 * Tells if a given job is closed.
 * @param job Job data.
 */
export const isClosed = (job?: Job) => {
  return job?.status === Status.Closed;
};

/**
 * Tells if a given job is canceled.
 * @param job Job data.
 */
export const isCanceled = (job?: Job) => {
  return job?.status === Status.Canceled;
};

/**
 * Determine whether a job is accepting new applications.
 */
export const isAcceptingApplications = (job: Job | undefined) => {
  if (!job) {
    return false;
  }

  if (isPublished(job) && !job.applicationDeadline) {
    return true;
  }

  const deadline = new Date(job.applicationDeadline);
  const today = new Date();
  return isPublished(job) && deadline > today;
};

/**
 * Tells if a given job is inactive.
 * @param job Job data.
 */
export const isInactive = (job?: Job) => {
  if (!job) {
    return false;
  }

  return isClosed(job) || isCanceled(job) || isPaused(job);
};

/**
 * Get a fully qualified public URL for the job.
 */
export const getPublicUrl = (
  job: Pick<Job, "slug">,
  page: Pick<Page, "slug">,
) => {
  // @todo Remove the replace() when the API drop the leading slash.
  return new URL(
    `/${page.slug.replace("/", "")}/${job.slug.replace("/", "")}`,
    location.origin,
  ).toString();
};

/**
 * Get a fully qualified public URL for the job's application page.
 */
export const getPublicApplyUrl = (
  job: Pick<Job, "slug">,
  page: Pick<Page, "slug">,
) => {
  return `${getPublicUrl(job, page)}/apply`;
};

/**
 * Tells if a given job is in approval process.
 * @param job Job data.
 */
export const inApprovalProcess = (job?: Job) => {
  return (
    job?.status === Status.Pending ||
    job?.status === Status.Approved ||
    job?.status === Status.Rejected
  );
};

/**
 * Tells if a given job has been published.
 * @param job Job data.
 */
export const hasBeenPublished = (job?: Job) => {
  return (
    job?.status === Status.Published ||
    job?.status === Status.Paused ||
    job?.status === Status.Canceled ||
    job?.status === Status.Closed
  );
};

const jobListingUrlStatusMap = {
  [Status.Published]: "/hr/jobs/published",
  [Status.Pending]: "/hr/jobs/pending",
  [Status.Approved]: "/hr/jobs/pending",
  [Status.Rejected]: "/hr/jobs/pending",
  [Status.Draft]: "/hr/jobs/draft",
  [Status.Paused]: "/hr/jobs/inactive",
  [Status.Closed]: "/hr/jobs/inactive",
  [Status.Canceled]: "/hr/jobs/inactive",
};

/**
 * Get job listing URL based on given job status.
 * @param job Job data.
 * @returns Job listing URL.
 */
export const getJobListingUrl = (job: Job | undefined) => {
  return jobListingUrlStatusMap[job?.status ?? Status.Draft];
};

/**
 * Get a formatted job levels string.
 * @param jobLevels Job levels array.
 * @returns Job levels string.
 */
export const getFormattedJobLevels = (jobLevels?: JobLevel[]) => {
  if (jobLevels?.length > 0) {
    return jobLevels.map((jobLevel) => jobLevel.name).join(", ");
  }
  return "";
};

/**
 * Get a formatted departments string.
 * @param departments Department array.
 * @param truncate Number of departments to show before truncation.
 * @returns Departments string.
 */
export const getFormattedDepartments = (departments?: Department[]) => {
  if (departments?.length > 0) {
    return departments.map((department) => department.name).join(", ");
  }
  return "";
};

/**
 * Validate job data. Throws on violations.
 * @param data Job data.
 */
export const validate = async ({ id, ...data }: Job) => {
  await api.request({
    url: id ? `/jobs/${id}` : "/jobs",
    method: id ? "PATCH" : "POST",
    data,
    params: { validate: 1 },
  });
};

/**
 * Get a last stage id.
 * @param job Job data.
 * @returns Departments string.
 */
export const getLastStageId = (job: Job) => {
  return job.stages?.length > 0 ? job.stages[job.stages.length - 1].id : null;
};

/**
 * Get a slug value without leading slash.
 * @param slug Slug value.
 * @returns Slug value without leading slash.
 *
 * @todo This is a temporary workaround until the API drop the leading slash
 * from slug values.
 */
export const getSlugWithoutSlash = (slug: string) => {
  return slug.replace(/^\//, "");
};
