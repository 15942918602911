import { Button } from "antd";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import c from "classnames";

import stylesheet from "./style.module.css";

import { Flex } from "~/src/components/flex";
import { useScreenSizeBreakpoint } from "~/src/lib/use-screen-size-breakpoint";
import breakpoints from "~/src/styles/breakpoints.module.css";

type Height = {
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
  xxl?: number | string;
};

type Props = {
  children: ReactNode;
  height: Height;
  targetId: string;
};

export const Cropper = ({ children, height, targetId }: Props) => {
  const { t } = useTranslation("components/cropper");
  const [expanded, setExpanded] = useState(false);
  const isFirstRender = useRef(true);
  const screenSize = useScreenSizeBreakpoint(window);

  const initialHeight = useMemo(() => {
    const orderedBreakpoints = Object.keys(breakpoints).map(
      (key) => key.split("-")[1],
    );

    for (let i = orderedBreakpoints.indexOf(screenSize); i >= 0; i--) {
      if (height[orderedBreakpoints[i]]) {
        return height[orderedBreakpoints[i]];
      }
    }
  }, [height, screenSize]);

  useEffect(() => {
    if (!targetId) {
      throw new Error("Target is not defined");
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!expanded) {
      const element = document.getElementById(targetId);

      if (!element) {
        return;
      }

      const rect = element.getBoundingClientRect();
      const isInViewport = rect.top >= 0;

      if (!isInViewport) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      isFirstRender.current = false;
    }
  }, [expanded, targetId]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const classes = [stylesheet["container"]];
  if (height) {
    Object.keys(height).forEach((key) => {
      classes.push(stylesheet[key]);
    });
  }

  if (expanded) {
    classes.push(stylesheet.expanded);
  }

  return (
    <Flex direction="vertical" align="center">
      <motion.div
        className={c(classes)}
        initial={{ height: initialHeight }}
        animate={{ height: expanded ? "auto" : initialHeight }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>

      <Button onClick={handleExpand} type="link">
        {expanded
          ? t("components/cropper:actions.hide")
          : t("components/cropper:actions.expand")}
      </Button>
    </Flex>
  );
};
