import { Typography } from "antd";

type Props = {
  paragraphs: string[];
};

export const Content = ({ paragraphs }: Props) => {
  return (
    <Typography>
      {paragraphs.map((paragraph, i) => (
        <p key={i}>{paragraph}</p>
      ))}
    </Typography>
  );
};
