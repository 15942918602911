import { UserOutlined } from "@ant-design/icons";
import { Avatar, Carousel, Comment } from "antd";

import stylesheet from "./style.module.css";

import { Box } from "~/src/components/box";
import { Container } from "~/src/components/container";
import { Page } from "~/src/lib/api/pages";

type Props = {
  page: Page;
};

export const Testimonials = ({ page }: Props) => {
  if (!page?.testimonials?.length) {
    return null;
  }

  return (
    <div id="testimonials" className={stylesheet.testimonials}>
      <Carousel>
        {page.testimonials.map((testimonial) => (
          <Box key={testimonial.id} paddingVertical="xl" paddingHorizontal="md">
            <Container width="md">
              <Comment
                avatar={
                  <Avatar
                    src={testimonial.picture.url}
                    alt={testimonial.name}
                    size="large"
                    icon={<UserOutlined />}
                  />
                }
                author={`${testimonial.name}, ${testimonial.role}`}
                content={testimonial.contents}
              />
            </Container>
          </Box>
        ))}
      </Carousel>
    </div>
  );
};
