import {
  default as Icon,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { useTranslation } from "next-i18next";
import { ReactNode } from "react";

import stylesheet from "./style.module.css";

import { Container } from "~/src/components/container";
import { Box } from "~/src/components/box";
import { Flex } from "~/src/components/flex";
import GlassdoorOutlined from "~/src/images/icons/glassdoor-outlined.svg";
import { Page } from "~/src/lib/api/pages";

type SocialLinkProps = {
  url?: string;
  label?: string;
  icon?: ReactNode;
};

const SocialLink = ({ url, label, icon }: SocialLinkProps) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" aria-label={label}>
      {icon}
    </a>
  );
};

type Props = {
  page: Page;
};

export const SocialLinks = ({ page }: Props) => {
  const { t } = useTranslation("pages/applicant/company");

  const { company, linkedin, facebook, twitter, instagram, glassdoor } =
    page ?? {};

  if (!linkedin && !facebook && !twitter && !instagram && !glassdoor) {
    return null;
  }

  return (
    <Box
      paddingVertical="xl"
      paddingHorizontal="md"
      className={stylesheet["social-links"]}
    >
      <Container width="md">
        <Flex align="center" className={stylesheet["flex-content"]}>
          <Box paddingHorizontal="lg">
            <Typography>
              <h4>{t("pages/applicant/company:social-links.heading")}</h4>
            </Typography>
          </Box>
          <Flex.Item grow={1}>
            <Flex gap="lg" justify="center">
              {linkedin ? (
                <SocialLink
                  url={linkedin}
                  label={t(
                    "pages/applicant/company:social-links.links.linkedin",
                    {
                      name: company?.name,
                    },
                  )}
                  icon={<LinkedinFilled />}
                />
              ) : null}

              {facebook ? (
                <SocialLink
                  url={facebook}
                  label={t(
                    "pages/applicant/company:social-links.links.facebook",
                    {
                      name: company?.name,
                    },
                  )}
                  icon={<FacebookFilled />}
                />
              ) : null}

              {twitter ? (
                <SocialLink
                  url={twitter}
                  label={t(
                    "pages/applicant/company:social-links.links.twitter",
                    {
                      name: company?.name,
                    },
                  )}
                  icon={<TwitterOutlined />}
                />
              ) : null}

              {instagram ? (
                <SocialLink
                  url={instagram}
                  label={t(
                    "pages/applicant/company:social-links.links.instagram",
                    {
                      name: company?.name,
                    },
                  )}
                  icon={<InstagramOutlined />}
                />
              ) : null}

              {glassdoor ? (
                <SocialLink
                  url={glassdoor}
                  label={t(
                    "pages/applicant/company:social-links.links.glassdoor",
                    {
                      name: company?.name,
                    },
                  )}
                  icon={<Icon component={GlassdoorOutlined} />}
                />
              ) : null}
            </Flex>
          </Flex.Item>
        </Flex>
      </Container>
    </Box>
  );
};
