const getVideoId = (stringUrl: string) => {
  const url = new URL(stringUrl);
  if (/^(www\.)?youtube\.com$/.test(url.hostname)) {
    return url.searchParams.get("v");
  } else if (url.hostname === "youtu.be") {
    return url.pathname.substring(1);
  }
  throw new Error(`Unrecognized YouTube URL: ${stringUrl}`);
};

type Props = {
  url: string;
  title?: string;
};

/**
 * Simple YouTube embed component.
 */
export const YouTubeEmbed = ({ url, title = "Video" }: Props) => {
  const id = getVideoId(url);

  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube-nocookie.com/embed/${id}`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};
