import { useTranslation } from "next-i18next";

import stylesheet from "./style.module.css";

import { Box } from "~/src/components/box";
import { Container } from "~/src/components/container";
import { YouTubeEmbed } from "~/src/components/youtube-embed";
import { Page } from "~/src/lib/api/pages";
import { Upload } from "~/src/lib/api/uploads";

const Media = ({ media }: { media: Upload | string }) => {
  const isVideo = typeof media === "string";

  return (
    <div className={stylesheet.media}>
      {isVideo ? (
        <YouTubeEmbed url={media} />
      ) : (
        <img
          alt=""
          src={media.url}
          width={media.meta.width}
          height={media.meta.height}
        />
      )}
    </div>
  );
};

type Props = {
  page: Page | undefined;
};

export const MediaGrid = ({ page }: Props) => {
  const { t } = useTranslation("pages/applicant/company");

  if (!page) {
    return null;
  }

  // Filter because videos can be received from API as empty strings.
  const videos = page.videos.filter(Boolean);

  const media = [].concat(page.pictures).concat(videos);

  if (media.length === 0) {
    return null;
  }

  return (
    <Box paddingVertical="xl">
      <Container width="md">
        <div
          className={stylesheet.grid}
          aria-label={t("pages/applicant/company:midia-grid.title")}
        >
          {media.map((media, index) => (
            <Media key={index} media={media} />
          ))}
        </div>
      </Container>
    </Box>
  );
};
