import { QuestionCircleOutlined } from "@ant-design/icons";
import { Affix, Button, Layout as AntLayout, Spin } from "antd";
import { ReactNode } from "react";
import { useQuery } from "react-query";

import stylesheet from "./style.module.css";

import { Brand } from "~/src/components/brand";
import { Container } from "~/src/components/container";
import { Flex } from "~/src/components/flex";
import { HelpLink } from "~/src/components/help-link";
import { SocialLinks } from "~/src/components/page/social-links";
import { Testimonials } from "~/src/components/page/testimonials";
import * as Pages from "~/src/lib/api/pages";
import { useSearchParams } from "~/src/lib/use-search-params";

type HeaderProps = {
  extra?: ReactNode;
};

export const Header = ({ extra }: HeaderProps) => {
  return (
    <AntLayout.Header className={stylesheet.header}>
      <Container width="lg">
        <Flex justify="flex-end">{extra}</Flex>
      </Container>
    </AntLayout.Header>
  );
};

const Footer = () => {
  return (
    <AntLayout.Footer className={stylesheet.footer}>
      <Container width="lg">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <Affix offsetBottom={0}>
            <HelpLink>
              <Button
                type="primary"
                size="large"
                icon={<QuestionCircleOutlined />}
              />
            </HelpLink>
          </Affix>

          <Flex justify="center">
            <Brand href="/" />
          </Flex>
        </div>
      </Container>
    </AntLayout.Footer>
  );
};

type Props = {
  children?: ReactNode;
  extra?: ReactNode;
  isLoading?: boolean;
};

export const Layout = ({
  children,
  extra,
  isLoading: isLoadingChildren = false,
}: Props) => {
  const { pageSlug } = useSearchParams({ pageSlug: "string" });

  const { data: { data: page } = {}, isLoading: isLoadingPage } = useQuery(
    Pages.getQueryKey({ slug: pageSlug }),
    () => Pages.get({ slug: pageSlug }),
  );

  const isLoading = isLoadingPage || isLoadingChildren;

  return (
    <Spin spinning={isLoading}>
      <AntLayout className={stylesheet.layout}>
        <Header extra={extra} />

        <AntLayout.Content>
          {children}

          <Testimonials page={page} />

          <SocialLinks page={page} />
        </AntLayout.Content>

        <Footer />
      </AntLayout>
    </Spin>
  );
};
