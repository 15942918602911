import breakpoints from "~/src/styles/breakpoints.module.css";

/**
 * Get the current screen size breakpoint.
 *
 * @param window - The window object.
 * @returns The current screen size breakpoint.
 */
export const useScreenSizeBreakpoint = (window: Window): string => {
  const {
    "screen-sm": sm,
    "screen-md": md,
    "screen-lg": lg,
    "screen-xl": xl,
    "screen-xxl": xxl,
  }: { [key: string]: string } = breakpoints;

  if (window.matchMedia(`(min-width: ${xxl})`).matches) {
    return "xxl";
  } else if (window.matchMedia(`(min-width: ${xl})`).matches) {
    return "xl";
  } else if (window.matchMedia(`(min-width: ${lg})`).matches) {
    return "lg";
  } else if (window.matchMedia(`(min-width: ${md})`).matches) {
    return "md";
  } else if (window.matchMedia(`(min-width: ${sm})`).matches) {
    return "sm";
  } else {
    return "xs";
  }
};
