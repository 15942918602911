import { Card, Typography } from "antd";
import { useTranslation } from "next-i18next";

import stylesheet from "./style.module.css";

import { Box } from "~/src/components/box";
import { Flex } from "~/src/components/flex";
import { Page } from "~/src/lib/api/pages";

type LogoProps = {
  page: Page;
};

const Logo = ({ page }: LogoProps) => {
  const { t } = useTranslation("pages/applicant/company");

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Box padding="xs">
        <img
          src={page.logo.url}
          className={stylesheet.logo}
          alt={t("pages/applicant/company:heading.logo", {
            company: page.company.name,
          })}
        />
      </Box>
    </Card>
  );
};

type Props = {
  companyPage: Page;
  title?: string;
};

export const Heading = ({ companyPage: page, title = page.title }: Props) => {
  const { logo } = page;

  return (
    <Box paddingVertical="xl" paddingHorizontal="md">
      <Flex
        direction="vertical"
        align="center"
        justify="center"
        gap="md"
        style={{ minHeight: "6.5em" }}
      >
        {logo ? <Logo page={page} /> : null}
        <Typography>
          <h1 style={{ textAlign: "center", marginBottom: 0 }}>{title}</h1>
        </Typography>
      </Flex>
    </Box>
  );
};
