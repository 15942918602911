import { useRouter } from "next/router";

/**
 * Supported types.
 */
type Type = "string" | "number" | "float" | "bool";

/**
 * Derive actual type from a type name, e.g. number from "number".
 */
type LiteralType<T extends Type> = T extends "string"
  ? string
  : T extends "number"
    ? number
    : T extends "float"
      ? number
      : T extends "bool"
        ? boolean
        : never;

/**
 * Map of parameters and their types.
 */
type TypeMap = { [key: string]: Type };

/**
 * Coerce a string to number.
 */
const parseIntValue = (raw: string) => {
  const value = parseInt(raw, 10);
  return isNaN(value) ? undefined : value;
};

/**
 * Coerce a string to float.
 */
const parseFloatValue = (raw: string) => {
  const value = parseFloat(raw);
  return isNaN(value) ? undefined : value;
};

/**
 * Coerce a string to boolean.
 */
const parseBoolValue = (raw: string) => {
  return /^yes|true|on|1$/i.test(raw);
};

/**
 * Get coerced values from querystring parameters.
 *
 * @example useSearchParams({ jobId: "number" }) -> { jobId: parseInt("123", 10) }
 *
 * @todo Doesn't currently support multiple parameters with same name, i.e. arrays.
 */
export const useSearchParams = <T extends TypeMap>(typeMap: T) => {
  const router = useRouter();

  return Object.fromEntries(
    Object.keys(typeMap).map((key) => {
      if (!router.query[key]) {
        return [key, undefined];
      }

      const type = typeMap[key];
      const raw = String(router.query[key]);

      switch (type) {
        case "number":
          return [key, parseIntValue(raw)];
        case "float":
          return [key, parseFloatValue(raw)];
        case "bool":
          return [key, parseBoolValue(raw)];
        default:
          return [key, raw];
      }
    }),
  ) as { [key in keyof T]: LiteralType<T[key]> };
};
