import { api, CommonListParams, Response } from "~/src/lib/api";
import { Company } from "~/src/lib/api/companies";
import { Id } from "~/src/lib/api/id";
import { Upload } from "~/src/lib/api/uploads";

/**
 * Page data.
 */
export type Page = Partial<{
  id: Id;
  testimonials: Testimonial[];
  title: string;
  description: string;
  slug: string;
  companyId: Id;
  company: Company;
  logo: Upload;
  logoId: Id;
  header: Upload;
  headerId: Id;
  pictures: Upload[];
  pictureIds: Id[];
  videos: string[];
  website: string;
  facebook: string;
  glassdoor: string;
  instagram: string;
  linkedin: string;
  twitter: string;
}>;

export type Testimonial = Partial<{
  id: Id;
  pictureId: Id;
  picture: Upload;
  name: string;
  role: string;
  contents: string;
}>;

/**
 * Get parameters.
 */
type GetByIdParams = { id: Id };
type GetBySlugParams = { slug: string };

/**
 * List parameters.
 */
export type ListParams = CommonListParams;

/**
 * Get the query key for the resource.
 * @see https://react-query.tanstack.com/guides/query-keys
 * @param params Query parameters.
 * @returns Query key.
 */
export const getQueryKey = (
  params?: GetByIdParams | GetBySlugParams | ListParams,
) => {
  return ["pages", params].filter(Boolean);
};

/**
 * List pages.
 * @param opts Query options.
 * @returns A promise of the response.
 */
export const list = async (params: ListParams) => {
  return (await api.get<Response<Page[]>>("/companies/pages", { params })).data;
};

/**
 * Get a single page by id.
 * @param id Id of the page.
 * @param slug Slug of the page.
 * @returns A promise of the response.
 */
export const get = async (params: GetByIdParams | GetBySlugParams) => {
  const ref = "id" in params ? params.id : params.slug;
  return (await api.get<Response<Page>>(`/companies/pages/${ref}`)).data;
};

/**
 * Create a new page.
 * @param data New page data.
 * @returns A promise of the response.
 */
export const create = async (data: Page) => {
  return (await api.post<Response<Page>>("/companies/pages", data)).data;
};

/**
 * Update existing page.
 * @param page Existing page data with id.
 * @returns A promise of the response.
 */
export const update = async ({
  id,
  ...data
}: Exclude<Page, "id"> & GetByIdParams) => {
  return (await api.patch<Response<Page>>(`/companies/pages/${id}`, data)).data;
};

/**
 * Destroy (delete) existing page.
 * @param params Get params.
 */
export const destroy = async ({ id }: GetByIdParams) => {
  await api.delete(`/companies/pages/${id}`);
};

/**
 * Validate page data. Throws on violations.
 * @param data Page data.
 */
export const validate = async ({
  id,
  ...data
}: Exclude<Page, "id"> & GetByIdParams) => {
  await api.request({
    url: id ? `/companies/pages/${id}` : "/companies/pages",
    method: id ? "PATCH" : "POST",
    data,
    params: { validate: 1 },
  });
};

/**
 * Given a company, select its default page.
 * For now we have only one page for each company that is
 * published at any given time but later we could have several
 * pages published at the same time. This would be function
 * to retrieve the default page for that company.
 *
 * @param company Company data.
 * @returns Default page data.
 */
export const getDefaultPage = (company: Company | undefined) => {
  return company?.pages ? company.pages[0] : null;
};

/**
 * Get a fully qualified public URL for the company page.
 */
export const getPublicUrl = (page: Pick<Page, "slug"> | undefined) => {
  if (!page?.slug) {
    return;
  }
  // @todo Remove the replace() when the API drop the leading slash.
  return new URL(`/${page.slug.replace("/", "")}`, location.origin).toString();
};
