import { Typography } from "antd";

import { Content } from "./content";

import { Cropper } from "~/src/components/cropper";
import { Box } from "~/src/components/box";
import { Container } from "~/src/components/container";
import { textToParagraphs } from "~/src/lib/text-helper";

type Props = {
  id?: string;
  title: string;
  text: string;
  expandable?: boolean;
};

const limit = 1250;

export const TextBlock = ({ title, text, id, expandable }: Props) => {
  const paragraphs = textToParagraphs(text);
  const isExpandable = expandable && text.length > limit;

  return (
    <Box paddingVertical="sm" paddingHorizontal="lg">
      <Container width="sm" id={id}>
        <Typography>
          {title ? <h4>{title}</h4> : null}
          {isExpandable ? (
            <Cropper height={{ xs: "30em", md: "20em" }} targetId={id}>
              <Content paragraphs={paragraphs} />
            </Cropper>
          ) : (
            <Content paragraphs={paragraphs} />
          )}
        </Typography>
      </Container>
    </Box>
  );
};
