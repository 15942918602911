import { Card } from "antd";
import { ReactNode } from "react";

import { Container } from "~/src/components/container";
import { Flex } from "~/src/components/flex";

type Props = {
  children: ReactNode;
};

export const DescriptionContainer = ({ children }: Props) => {
  return (
    <Flex direction="vertical" gap="xl">
      <Container width="md">
        <Card>{children}</Card>
      </Container>
    </Flex>
  );
};
